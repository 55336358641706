.search-marquee {
    white-space: nowrap;
    overflow: hidden;
    transition: 2.3s !important;
}

.search-marquee:hover {
    text-overflow: ellipsis !important;
    overflow: visible !important;
    transform: translateX(-50%);
    width: auto;
}

.search-dropdown-marquee {
    transition: 3.3s !important;
}

.search-dropdown-marquee:hover {
    transform: translateX(-50%);
    width: fit-content;
}

.search-element {
    position: absolute;
    top: 0px;
    height: 32px;
    width: 100px;
}

.ant-form label,
.ant-steps-item-title,
.ant-divider-inner-text {
    font-weight: 500;
}

.ant-modal-title {
    font-size: 15px;
}
