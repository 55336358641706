.steps-content {
    min-height: 150px;
    margin-top: 20px;
    // padding-top: 80px;
    // text-align: center;
    // background-color: #fafafa;
    // border: 1px dashed #e9e9e9;
    // border-radius: 2px;
}

// .steps-action {
//     margin-top: 24px;
//     margin-bottom: -20px;
//     // display: block;
//     text-align: right;
//     font-size: 10px;
// }

// .steps-description {
//     margin-top: 10px;
// }

// .ant-steps-item-title {
//     font-size: 12px;
// }
