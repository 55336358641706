/*Util classes*/

:root {
    --sticky-width: 80px;
}

.buttons-sticky {
    //  width: calc(100% - var(--sticky-width)) !important;
    z-index: 10;
    bottom: -10px !important;
}

@media only screen and (max-height: 2160px) {
    .buttons-sticky {
        bottom: -11px !important;
    }
}

@media only screen and (max-height: 522px) {
    .buttons-sticky {
        bottom: -11px !important;
    }
}

@media only screen and (max-height: 457px) {
    .buttons-sticky {
        bottom: -10px !important;
    }
}

@media only screen and (max-height: 365px) {
    .buttons-sticky {
        bottom: -11px !important;
    }
}

.ny-logo {
    width: var(--sticky-width) !important;
}

.default-widget-modal-width {
    width: 669px !important;
    transition: width 0.233s !important;
}

.extended-widget-modal-width {
    width: 969px !important;
    transition: width 0.233s !important;
}

.default-widget-col-width {
    max-width: 100%;
    transition: max-width 0.33s !important;
}

.edit-transfer-view-table-width-visible {
    width: 100% !important;
    transition: width 0.233s !important;
    height: 233px; // var
    overflow-y: auto;
    overflow-x: hidden;
}

.edit-transfer-view-table-width-hidden {
    width: 0px !important;
    overflow-y: hidden;
    overflow-x: hidden;
    float: right;
    transition: width 0.233s !important;
}

.edit-transfer-view-transfer-width-visible {
    width: 100% !important;
    transition: width 0.233s !important;
    height: 272px; // var
}

.edit-transfer-view-transfer-width-hidden {
    width: 0px !important;
    overflow: hidden;
    float: left;
    transition: width 0.233s !important;
}

.switch-red-background {
    margin-left: 4px;
    //  background-color: rgb(242, 55, 55);
    transition: background-color 1.233s ease, color 0.3s ease !important;
}

.switch-green-background {
    margin-left: 4px;
    //   background-color: rgb(12, 188, 12);
    transition: background-color 1.233s ease, color 0.3s ease !important;
}

.row-dragging {
    visibility: visible;
    background: #fafafa;
    border: 1px solid #ccc;
}

.table-row-force-visible tr {
    visibility: visible !important;
    background-color: yellow;
    opacity: 100;
}

.row-dragging td {
    visibility: visible;
    padding: 16px;
}

.row-dragging tr {
    visibility: visible !important;
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.default-widget-col-width {
    max-width: 100%;
    transition: max-width 0.33s !important;
}

.extended-widget-col-width {
    max-width: 66.666667%;
    transition: max-width 0.33s !important;
}
.small-widget-col-width {
    max-width: 33.333333%;
    transition: max-width 0.33s !important;
}

.main-layout .main-content {
    height: var(--dashboardHeight);
    max-height: var(--dashboardMaxHeight);
    margin-bottom: var(--dashboardMarginBottom);
}

// @media only screen and (max-height: 2160px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 85vh !important;
//     }
// }

// @media only screen and (max-height: 1440px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 78vh !important;
//     }
// }

// @media only screen and (max-height: 1200px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 74vh !important;
//     }
// }

// @media only screen and (max-height: 1080px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 71vh !important;
//     }
// }

// @media only screen and (max-height: 1024px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 59vh !important;
//     }
// }

// @media only screen and (max-height: 900px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 57vh !important;
//     }
// }

// @media only screen and (max-height: 768px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 52vh !important;
//     }
// }

// @media only screen and (max-height: 610px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 52vh !important;
//     }
// }

// @media only screen and (max-height: 522px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 52vh !important;
//     }
// }

// @media only screen and (max-height: 457px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 52vh !important;
//     }
// }

// @media only screen and (max-height: 365px) {
//     .main-layout .main-content .ant-table-body {
//         max-height: 52vh !important;
//     }
// }

.collapsed-menu-title {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: -0.4rem;
    margin-bottom: -0.3rem;
    background: rgba(255, 255, 255, 0.04);
}
@media only screen and (max-width: 1681px) {
    .main-layout .main-content {
        // max-height: 86vh;
    }
    .statistic-card-items-path {
        margin-left: -4% !important;
    }
}

.ant-table-body {
    max-height: 64vh;

    @media only screen and (max-width: 1681px) {
        max-height: 51vh !important;
    }
}

.data-table-general .ant-table-header {
    max-height: 81px;
}

.ant-slider-with-marks {
    margin-bottom: 24px;
}

.data-table-general .ant-table {
    max-height: 67.5vh;
}

.data-table-general .ant-table {
    max-height: 67.5vh;
}

.data-table-general .ant-table-body {
    max-height: 60vh !important;
    @media only screen and (max-width: 1681px) {
        max-height: 49vh !important;
    }
}

/* .ant-card-body {
    border-radius: 15px;
} */

.version {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0px;
    font-style: italic;
}

.env {
    font-weight: bold;
    display: inline-block;
    color: red;
    margin-bottom: 0px;
}

.height-100 {
    height: 100%;
}

.margin {
    margin: 1em;
}

.margin-bottom {
    margin-bottom: 1em;
}

.margin-top {
    margin-top: 1em;
}

.margin-left {
    margin-left: 1em;
}

.margin-right {
    margin-right: 1em;
}
//scrollbar start
main::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.ant-menu::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.ant-menu:hover::-webkit-scrollbar,
div:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
main::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
.ant-menu::-webkit-scrollbar {
    background: grey;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
body::-webkit-scrollbar,
.ant-tabs-content-holder::-webkit-scrollbar {
    display: none;
}
.ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

div,
.ant-menu {
    scrollbar-width: thin;
}

div:hover,
.ant-menu:hover,
.ant-table-body,
main {
    scrollbar-width: auto;
}
html {
    scrollbar-width: none;
}
//scrollbar end

.ant-input:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.ant-input::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.ant-input::-webkit-scrollbar-thumb {
    background: gray;
}

.ant-input::-webkit-scrollbar-thumb:hover {
    background: #555;
}
// input scroll

.hidden {
    display: none !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.tabs-sticky .ant-tabs-content-holder {
    //  max-height: 69vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.tabs-sticky2 .ant-tabs-content-holder {
    //  max-height: 69vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.ny-note-modal-icon {
    font-size: 18px;
    vertical-align: middle !important;
}

.ny-note-modal-icon svg:hover {
    color: #1890ff;
}

.ny-input-number .ant-input-number-handler-wrap {
    display: none !important;
}

.ny-input-number input {
    text-align: right;
}

.ny-fixed-button {
    position: absolute !important;
    right: 20px;
    bottom: 70px;
    width: 44px !important;
    height: 44px !important;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.08), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.08), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    border: none !important;
}

.ny-custom-button {
    float: left;
    font-size: 18px;
    margin: 5px 0px 0px 8px;
    cursor: pointer;
}

.ny-custom-button :hover {
    color: rgb(64, 169, 255);
}

table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
}

table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}

.sortable-item {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 18px 20px;
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;
    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    margin: 10px 5px;
    height: 40px;
}

.sortable-item-list {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 18px 20px;
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;
    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    margin: 4px 5px;
    height: 40px;
}

.title-item {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 18px 20px;
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;
    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    margin: 6px 5px;
    height: 40px;
}

.title-item2 {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 18px 20px;
    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;
    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    margin: 6px -11px;
    height: 40px;
}

.sortable-item-table {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: grab;
    overflow: hidden;
    font-size: 13px;
}

.sorted-item-span {
    display: flex;
    align-self: flex-start;
    margin-top: -5px;
    margin-left: 35px;
    margin-right: 0px;
}

.sortable-item-dragged {
    opacity: 0.5;
}

.ny-delete-icon {
    font-size: 16px;
    position: absolute;
    right: 55px;
    bottom: 7px;
    padding: 5px;
    border-radius: 5px;
}

.ny-sort-switch {
    text-align: center;
    padding: 8px;
}

.ny-sort-icon {
    right: 15px;
    cursor: grab;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 7px;
}

.ny-sort-icon :hover {
    color: rgb(64, 169, 255);
}

.item-sort-icon {
    // left: 15px;
    cursor: grab;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 7px;
}

.item-sort-icon :hover {
    color: rgb(64, 169, 255);
}

.ant-select-multiple .ant-select-selection-item {
    height: 100% !important;
}
.ant-select-selector {
    height: 100% !important;
}

.ny-search-field-select {
    margin-right: 28px !important;
    min-height: 28px;
}

.ny-search-field-no-margin {
    min-height: 28px;
    overflow: hidden;
}

.ny-search-field-button {
    position: absolute !important;
    height: 100% !important;
    right: 0px !important;
}

.ny-add-translation-button {
    position: absolute !important;
    right: 12px !important;
    top: 25px !important;
}

// CALENDAR
.ant-picker-calendar-mode-switch {
    display: none !important;
}
.ant-picker-cell-holiday-name {
    color: #ff4d4f !important;
    float: left;
    padding-left: 5px;
    width: 70%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ant-badge-dot {
    width: 8px !important;
    height: 8px !important;
    box-shadow: 0 0 0 0px #fff !important;
}

.ant-picker-cell-opacity {
    opacity: 0.5;
}

.ny-holiday-border {
    border-color: #ff4d4f !important;
}

.ny-weekend-border {
    border-color: #faad14 !important;
}

.ny-today-border {
    border-color: #1890ff !important;
}

.ny-calendar-tag {
    width: 100%;
    text-align: center;
    padding: 5px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* .ny-calendar-radio-button {
    color: #3a3a3a !important;
    border-color: #d9d9d9 !important;
} */

// END CALENDAR

// NOTIFICATION
.ny-notification-button .ny-notification-button-pin .ny-notification-button-delete {
    cursor: pointer;
    margin-top: 5px;
}
.ny-notification-button .ny-notification-button-pin .ny-notification-button-delete:hover {
    color: rgb(64, 169, 255);
}
.ny-notification-button {
    width: 50%;
}
.ny-notification-button-pin {
    width: 10%;
    top: -1px;
}
.ny-notification-button-delete {
    width: 40%;
}

.ny-notification-panel {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.ny-notification {
    margin-top: 2px;
    padding-top: 4%;
    padding-left: 2%;
}

.ny-notification :hover {
    color: rgb(64, 169, 255);
}

.ny-notification-selected {
    margin-top: 2px;
    padding-top: 4%;
    padding-left: 2%;
    border-radius: 15px;
    background-color: rgba(195, 221, 241, 0.56) !important;
}

.ny-notification-selected :hover {
    color: rgb(64, 169, 255);
}

.ny-notification-unread {
    margin-top: 2px;
    padding-top: 4%;
    padding-left: 2%;
    border-radius: 15px;
    background-color: rgba(195, 221, 241, 0.26) !important;
}

.ny-notification-unread :hover {
    color: rgb(64, 169, 255);
}

// END NOTIFICATION
.ny-select {
    padding-top: 12px !important;
    padding-left: 12px !important;
    border-radius: 15px !important;
    background-color: rgba(195, 221, 241, 0.26) !important;
    width: 100% !important;
    top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 25px !important;
    min-height: 60px !important;
}

.menu-item {
    overflow-x: scroll;
}

.ny-data-table-modal-footer {
    position: absolute;
    z-index: 1;
    bottom: -37px;
}

.ny-modal-footer-content {
    position: absolute;
    top: 8px;
}

.item-table-info-content {
    position: absolute;
    top: 5px;
    // left: 15px;
}

.leaflet-routing-alternatives-container {
    display: none;
}

.ant-popover {
    z-index: 1000 !important;
}

.ny-contact {
    cursor: pointer;
}

.ny-contact:hover {
    color: rgb(var(--color-primary, (64, 169, 255))) !important;
}

.ny-popover .ant-popover-inner-content {
    padding: 0px !important;
}

.ny-upload .ant-upload {
    width: 100%;
}

.ny-list .ant-col {
    height: 100%;
}

.ny-list-item {
    height: calc(100% - 10px);
}

.ny-blink {
    animation: ny-blink-animation 1s steps(5, start) infinite;
    -webkit-animation: ny-blink-animation 1s steps(5, start) infinite;
}
@keyframes ny-blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes ny-blink-animation {
    to {
        visibility: hidden;
    }
}

.statistic-card-buttons {
    //margin-top: 1rem;
    width: 100%;
    justify-content: center;
    display: flex;
    cursor: default !important;

    box-shadow: 0px 0 0 0 !important;
}

.statistic-card-items {
    // margin-top: 1rem;
    justify-content: center;
    // display: flex;
    cursor: default !important;
    //margin-bottom: 5px !important;
    margin: 5px !important;

    box-shadow: 0px 0 0 0 !important;
}

.statistic-card-items-path {
    display: flex;
    justify-content: center;
    cursor: default !important;
    // margin: 5px !important;
    box-shadow: 0px 0 0 0 !important;
    // width: 80% !important;
    @media only screen and (max-width: 1681px) {
        margin-left: -6% !important;
    }
}

.statistic-card-items-path .ant-steps-item .ant-steps-item-finish {
    width: 80% !important;
}

.statistic-card-items-path .ant-steps-item .ant-steps-item-finish {
    width: 80% !important;
}

.statistic-card-items-path .ant-steps .ant-steps-horizontal .ant-steps-label-vertical .ant-steps-dot {
    width: 68% !important;
}

.statistic-card-items-path .ant-steps-item-finish {
    // width: 80% !important;
    flex: auto;
}

.statistic-card-items-path .ant-steps-horizontal {
    justify-content: center;
    flex: auto;
}

.statistic-card,
.statistic-card-light {
    margin-bottom: 5px !important;
    cursor: pointer;
}

.statistic-card:hover,
.statistic-card-active {
    // .ant-statistic-content {
    //     color: rgb(64, 169, 255) !important;
    // }

    .ant-statistic-title {
        color: rgba(0, 0, 0, 1);
    }
}

.statistic-card-warning {
    .ant-statistic-content {
        color: #faad14 !important;
    }
}

.statistic-card-light:hover,
.statistic-card-warning-light:hover,
.statistic-card-warning:hover,
.statistic-card-active-light {
    color: rgb(64, 169, 255) !important;
}

.statistic-card-warning-light {
    color: #faad14 !important;
}

.ny-notification-widget-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ny-notification-widget-badge {
    float: right;
    top: 10px;
    right: 6px;
}

.ny-notification-widget-footer {
    margin-top: 4px;
    float: right;
    //  color: black;
    font-size: 11px;
}

.ny-notification-widget-list-item {
    height: calc(100% - 10px);
    margin-bottom: 0px !important;
}

.ny-personal-notes-widget-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // display: inline;
}

.ny-personal-notes-widget-badge {
    float: right;
    top: 10px;
    right: 6px;
}

.ny-personal-notes-widget-footer {
    margin-top: 4px;
    //float: right;

    /*   font-size: 11px; */
    width: 80%;
}

.ny-personal-notes-pushpin {
    float: right;
    z-index: 999999;
}

.ny-personal-notes-pushpin:hover {
    color: #ff4d4f;
}

.ny-personal-notes-pushpin-btn {
    float: right;
    // z-index: 999999;
}

.ny-personal-notes-widget-list-item {
    height: calc(100% - 10px);
    margin-bottom: 0px !important;
}

.ny-personal-notes-widget-delete {
    float: right;
}

.ny-personal-notes-widget-avatar {
    font-size: 20px;
    margin-top: 6px;
    margin-left: 1px;
    margin-right: -8px;
    // color: #ff4d4f; -- color ne dela,obrisati
}

.ny-services-order-upload .ant-upload-select {
    display: none !important;
}
.ny-dashboard-empty svg {
    width: 100% !important;
}

.ny-dashboard-light-empty {
    border: 0px !important;
    border-radius: 10px !important;
    top: -10px;
}

.webshop-card-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: break-spaces !important;
    font-size: 12px !important;
    height: 80px;
}
.task-service-card-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces !important;
    font-size: 14px !important;
    height: 45px;
}

.ny-text-line-overflow-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 24px;
}

.inline {
    display: inline-block;
}
.wrap {
    display: table;
    height: 45px;
}
.wrap p {
    display: table-cell;
    vertical-align: middle;
}
.ny-breadcrumb {
    cursor: pointer;
}
.ny-breadcrumb:hover {
    color: rgb(64, 169, 255) !important;
}
.ny-webshop-radio-group {
    width: 100%;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.ny-webshop-radio-group label {
    width: 50%;
    text-align: center;
}

.ant-badge-count {
    z-index: 2 !important;
}

.ny-order-view-access-settings-buttons {
    margin-top: 10px;
    text-align: right;
}

.ny-travel-warrant-buttons {
    text-align: right;
    padding-top: 5px;
}

.travel-warrant-overview-footer {
    background-color: var(--color-card-background);
    border-radius: 1px;
}

.travel-warrant-cards-view-title {
    margin-left: 30px;
    font-size: large;
}

.ny-icon:hover {
    color: rgb(64, 169, 255) !important;
}
.ny-search-field-employee .ny-search-field-button {
    margin-right: 4px !important;
}
.ny-travel-warrant-calendar-tag {
    white-space: initial !important;
    margin-bottom: 5px !important;
    text-align: center;
    width: 100%;
}

.ant-form-item-tooltip {
    margin-left: 5px !important;
    margin-top: 1px !important;
    color: #1890ff !important;
}

.ny-popover-widget .ant-popover-inner-content {
    padding: 0px !important;
}

.ny-popover-widget .ant-popover-inner {
    border-radius: 15px !important;
}

.ny-widget-remove {
    position: absolute;
    right: -5px;
    top: -5px;
    cursor: pointer;
}

.ny-widget-remove:hover {
    color: red;
}
.ny-widget-card-extra-light {
    width: 100% !important;
    height: 100% !important;
    line-height: 47px;
    font-size: 28px !important;
}
.ny-widget-card-extra-light > span {
    vertical-align: middle !important;
    height: 100% !important;
    margin-bottom: 7px !important;
}
.ny-widget-card-extra-light > span > svg {
    height: 100% !important;
}
.dashboard-widget-box-unlock > .ny-widget-card {
    pointer-events: none;
}
.statistic-card-light > svg {
    height: 100% !important;
}

.travel-warrant-overview-legend {
    font-size: 20px;
    margin-top: 2px;
}
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 24%;
    margin-right: 8px;
}

.upload-list-inline {
    .ant-upload-list-item {
        .ant-upload-list-item-info {
            .ant-upload-span {
                .ant-upload-list-item-name {
                    color: #1890ff;
                    cursor: pointer;
                }
            }
        }
    }
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
}
.travel-warrant-plan-steps .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #52c41a !important;
}
.travel-warrant-plan-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #52c41a !important;
}
.travel-warrant-plan-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .anticon {
    vertical-align: unset !important;
}
.travel-warrant-plan-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .anticon {
    vertical-align: unset !important;
}
.travel-warrant-plan-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #52c41a !important;
}
.fc-button {
    background-color: #40a9ff;
    border: none !important;
}
.fc-button-active {
    opacity: 0.65 !important;
}
.ant-pagination {
    position: relative !important;
}
.rc-virtual-list-scrollbar-show {
    display: block !important;
    overflow-y: auto;
}

.advance-statistic-color {
    .ant-statistic-content {
        color: #3f8600 !important;
    }
}
.subvention-statistic-color {
    .ant-statistic-content {
        color: #2491fc !important;
    }
}

.yellow-desc-item {
    font-size: 13px;
    color: #d48806;
    font-weight: 500;
    float: right;
}

.green-desc-item {
    font-size: 13px;
    color: #52c41a;
    font-weight: 500;
    float: right;
}

.blue-desc-item {
    font-size: 13px;
    color: #2c7be5;
    font-weight: 500;
    float: right;
}

.modal-borderless {
    .ant-modal-content {
        .ant-modal-header {
            border-bottom: none !important;
        }
        .ant-modal-footer {
            border-top: none !important;
        }
    }
}

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
}

.rightAlign {
    padding-right: 8px !important;
}

.report-fix-needed {
    background-color: #fff7e6;
    color: #d48806;
    border-color: #faad14 !important;
}

.report-fix-needed:hover {
    background-color: #ffe8b8;
    color: black;
    border-color: #faad14 !important;
}

.report-fix-needed-dark {
    background-color: #2b1d11;
    // color: white;
    border-color: #96670c !important;
}

.report-fix-needed-dark:hover {
    background-color: #403328;
    color: white;
    border-color: #96670c !important;
}
.webshop-actions-div {
    position: sticky;
    top: 0;
    margin-bottom: 50px;
    max-width: 200px;
}
.webshop-actions-select-category {
    min-width: 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}
.webshop-actions-select {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.scrollbar-on-hover {
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-gutter: stable;
}

.scrollbar-on-hover:hover {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
}

.ag-format-container {
    height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.ag-format-container:hover {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-gutter: stable;
}

.ag-courses_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    flex-direction: column;

    padding-top: 18px;
}
.ag-courses_item {
    width: 170px;
    margin: 0px 12px 20px;

    overflow: hidden;

    border-radius: 5px;
}
.ag-courses-item_link {
    display: flex;
    height: 90px;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
    text-decoration: none;
    color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
}
.ag-courses-item_title {
    overflow: hidden;

    font-weight: bold;
    font-size: 14px;
    text-align: center;
    position: relative;

    z-index: 2;
}
.ag-courses-item_date-box {
    font-size: 18px;
    color: #fff;

    z-index: 2;
    position: relative;
}
.ag-courses-item_date {
    font-weight: bold;
    color: #f9b234;

    -webkit-transition: color 0.5s ease;
    -o-transition: color 0.5s ease;
    transition: color 0.5s ease;
}
.ag-courses-item_bg {
    height: 145px;
    width: 145px;

    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;

    border-radius: 50%;

    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@media only screen and (max-width: 1450px) {
    .ag-courses_item {
        -ms-flex-preferred-size: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
    }
}

@media only screen and (max-width: 767px) {
    .ag-format-container {
        width: 96%;
    }
}
@media only screen and (max-width: 639px) {
    .ag-courses_item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
    .ag-courses-item_title {
        line-height: 1.25;
    }
    .ag-courses-item_link {
        padding: 22px 40px;
    }
}

.portal-content {
    width: 77vw;
}

.portal-content-collapsed {
    width: 83vw;
}

@media only screen and (min-width: 1745px) {
    .portal-content {
        width: 75vw;
    }
    .portal-content-collapsed {
        width: 81vw;
    }
}

@media only screen and (min-width: 1925px) {
    .portal-content {
        width: 79vw;
    }
    .portal-content-collapsed {
        width: 85vw;
    }
}

@media only screen and (min-width: 2135px) {
    .portal-content {
        width: 82vw;
    }
    .portal-content-collapsed {
        width: 87vw;
    }
}

@media only screen and (min-width: 2400px) {
    .portal-content {
        width: 81vw;
    }
    .portal-content-collapsed {
        width: 86vw;
    }
}

@media only screen and (min-width: 2880px) {
    .portal-content {
        width: 84vw;
    }
    .portal-content-collapsed {
        width: 88vw;
    }
}

@media only screen and (min-width: 3840px) {
    .portal-content {
        width: 88vw;
    }
    .portal-content-collapsed {
        width: 91vw;
    }
}

@media only screen and (min-width: 5760px) {
    .portal-content {
        width: 92vw;
    }
    .portal-content-collapsed {
        width: 94vw;
    }
}

@media only screen and (min-width: 7680px) {
    .portal-content {
        width: 94vw;
    }
    .portal-content-collapsed {
        width: 95vw;
    }
}

.print-style {
    .ant-typography,
    .ant-typography-print,
    .ant-typography-print-desc,
    .ant-descriptions-title,
    .ant-typography-print-title,
    .ant-descriptions-item-label,
    .ant-divider-inner-text,
    .ant-form,
    .ant-table-thead > tr > th,
    .ant-table {
        color: black;
    }
    .ant-descriptions-bordered .ant-descriptions-item-content,
    .ant-descriptions-bordered .ant-descriptions-item-label {
        border: 1px solid #f0f0f0 !important;
    }
    .ant-descriptions-bordered .ant-descriptions-view {
        border: none !important;
    }
    .ant-table-cell {
        border-bottom: 1px solid #f0f0f0 !important;
    }
    .ant-divider-horizontal.ant-divider-with-text {
        border-top-color: #f0f0f0;
    }

    .ant-typography-print-text {
        color: #1377de !important;
    }
}

.print-style-blue {
    font-weight: bold;
    color: rgb(var(--color-primary, 19, 119, 222)) !important;
}

.ant-card-type-inner .ant-card-head {
    border-radius: 15px 15px 0 0;
}
.ant-card-type-inner .ant-card-body {
    border-radius: 0 0 15px 15px;
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
    display: block !important;
    right: 0;
}
.ant-menu-submenu-arrow::after,
.ant-menu-submenu-arrow::before {
    height: 0.5px;
}
.ant-menu.ant-menu-dark {
    .ant-menu-submenu-selected,
    .ant-menu-submenu-open {
        .ant-menu-submenu-arrow::after,
        .ant-menu-submenu-arrow::before {
            height: 1.5px;
        }
    }
}
.ant-menu-submenu-selected,
.ant-menu-submenu-open {
    .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-arrow::before {
        height: 0.5px;
        color: #1377de;
    }
}
.ant-card-grid-hoverable,
.ant-card-hoverable {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
    background-color: transparent;
}

.ant-card-grid-hoverable::before,
.ant-card-hoverable::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(200, 200, 200, 0.2) 10%, transparent 10%);
    transition: transform 0.5s ease-out;
    transform: translate(-50%, -50%) scale(0);
    z-index: -1;
    border-radius: 50%;
}

.ant-card-grid-hoverable:hover::before,
.ant-card-hoverable:hover::before {
    transform: translate(-50%, -50%) scale(4);
}

.ant-card-grid-hoverable:hover,
.ant-card-hoverable:hover {
    box-shadow: none !important;
}

// tabs
.horizontal-menu-tabs {
    // left: 108px;
    margin-left: 25px !important;
    .ant-menu-item,
    .ant-menu-submenu {
        position: relative;
        display: inline-block;
        padding: 0.7em 2em 0.5em;
        text-decoration: none;
        margin: 0 -0.3em;
    }
}
.horizontal-menu-tabs .ant-menu-item::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-bottom: none;
    border-radius: 0.4em 0.4em 0 0;
    transform-origin: bottom;
    transform: scale(1.2, 1.3) perspective(0.7em) rotateX(5deg);
}
.horizontal-menu-tabs:not(.ant-menu-dark) > .ant-menu-item-selected,
.horizontal-menu-tabs:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: white !important;
}
.horizontal-menu-tabs .ant-menu-item.ant-menu-item-selected::before,
.horizontal-menu-tabs .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected::before {
    background-color: rgb(var(--color-primary, #1890ff));
    transform-origin: bottom;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected:hover > div {
    color: white !important;
}

.horizontal-menu-tabs .ant-menu-item:hover::before {
    transform-origin: bottom;
}
.horizontal-menu-tabs .ant-menu-item:not(.ant-menu-item-selected):hover {
    color: rgb(var(--color-primary, #1890ff)) !important;
    transform-origin: bottom;
}
.horizontal-menu-tabs .ant-menu-submenu::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-bottom: none;
    border-radius: 0.4em 0.4em 0 0;
    transform-origin: bottom;
    transform: scale(1.2, 1.3) perspective(0.7em) rotateX(5deg);
}
.horizontal-menu-tabs .ant-menu-overflow-item .ant-menu-item {
    color: green;
}
// .horizontal-menu-tabs:not(.ant-menu-dark) > .ant-menu-submenu-selected .ant-menu-submenu-title,
// .horizontal-menu-tabs:not(.ant-menu-dark) > .ant-menu-submenu:hover {
//     color: var(--text-color-active, white);
// }
.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected::after,
.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected::after {
    border-bottom: transparent !important;
}
.horizontal-menu-tabs .ant-menu-submenu .ant-menu-submenu-title {
    z-index: 2;
}
.horizontal-menu-tabs .ant-menu-submenu.ant-menu-submenu-selected::before {
    color: #1890ff !important;
    background-color: #1890ff;
    // transform-origin: bottom;
}
.horizontal-menu-tabs .ant-menu-submenu:not(.ant-menu-submenu-selected):hover {
    color: #1890ff !important;
    transform-origin: bottom;
}
.horizontal-menu-tabs .ant-menu-submenu:hover::before {
    transform-origin: bottom;
}

.ant-form-item-required {
    font-style: italic;
}

.ant-form-item-row:has(> div > label.ant-form-item-required) {
    .ant-form-item-control .ant-form-item-control-input {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        .ant-form-item-control-input-content .ant-input:focus {
            border-left: 1px solid transparent;
        }
        .ant-form-item-control-input-content .ant-input:hover {
            border-left: 1px solid transparent;
        }
    }
}
.ant-form-item-row:has(.ant-form-item-control
        .ant-form-item-control-input
        .ant-form-item-control-input-content
        input.ant-input-disabled) {
    .ant-form-item-label {
        font-style: italic;
    }
}

.ant-table.ant-table-small .ant-table-filter-trigger {
    background-color: inherit;
    color: #1890ff; //rgb(64 169 255 / 59%);
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2px;
}
.ant-table.ant-table-small .ant-table-filter-trigger.active {
    background-color: #1890ff; // rgb(64 169 255 / 59%);
    color: #ffffff;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent;
}

// .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
//     border-color: var(--bg-color, #1890ff);
//     background: var(--bg-color, #1890ff);
// }

// .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
//     background: var(--bg-color, #1890ff);
//     border-color: var(--bg-color, #1890ff);
//     filter: brightness(80%);
// }

// .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
//     border-color: var(--bg-color, #1890ff);
// }

// .ant-radio-button-wrapper,
// .ant-radio-button-wrapper a,
// .ant-radio-button-wrapper .ant-badge {
//     color: var(--text-color, black);
// }

// .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
//     color: var(--text-color-active, white);
// }

// .ant-btn-primary,
// .ant-btn-primary {
//     background: var(--bg-color, #1890ff);
//     border-color: var(--bg-color, #1890ff);
// }

// .ant-btn-primary:focus,
// .ant-btn-primary:hover {
//     background: var(--bg-color, #1890ff);
//     border-color: var(--bg-color, #1890ff);
//     filter: brightness(80%);
// }

// .ant-btn-link {
//     color: var(--text-color, #1890ff);
// }

// .ant-btn-link:hover {
//     color: var(--text-color-active, #1890ff);
// }

.ant-radio-group.ant-radio-group-outline.custom-radio-group-options,
.ant-checkbox-group.custom-checkbox-group-options {
    max-height: 50vh;
    overflow: auto;
}

.ant-select-open .ant-select-selection {
    box-shadow: green !important;
}

.graph-viewport {
    outline-color: rgb(var(--color-primary, #1890ff)) !important;
}

/* primary color background, border */
.ant-btn-primary,
.ant-radio-group-solid.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-checkbox-checked .ant-checkbox-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background: rgb(var(--color-primary));
    border-color: rgb(var(--color-primary));
}

/* checkbox border on focus/hover */
.ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
        border-color: rgb(var(--color-primary));
    }
}

/* button, link hover/focus */
.ant-btn-link {
    color: rgb(var(--color-primary));
}

/* button hover/focus */
.ant-btn-primary:focus,
.ant-btn-primary:hover,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background: rgba(var(--color-primary), 0.75);
    border-color: rgba(var(--color-primary), 0.75);
    color: white !important;
}

/* badge color in checked radio button */
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked > span .ant-badge {
    color: white;
}

/* active menu items */
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal).ant-menu-item-selected,
.horizontal-menu-tabs.ant-menu-item.ant-menu-item-selected::before,
.horizontal-menu-tabs.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected::before,
.ant-steps-item-finish.ant-steps-item-icon > .ant-steps-icon.ant-steps-icon-dot {
    background: rgb(var(--color-primary));
}

/* switch, selected radio button */
.ant-switch-checked,
.horizontal-menu-tabs .ant-menu-item.ant-menu-item-selected::before,
.horizontal-menu-tabs .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected::before,
.ant-radio-checked .ant-radio-inner:after {
    background-color: rgb(var(--color-primary));
}

/* active button, pagination */
.fc-button,
.ant-table.ant-table-small .ant-table-filter-trigger.active,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(var(--color-primary, 64, 169, 255)) !important;
    color: white !important;
}

/* hover/focus on tabs, buttons, menu items */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover,
.ny-note-modal-icon svg:hover,
.ant-radio-button-wrapper:hover,
.ant-card-actions > li > span:hover,
.extraFile:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: rgb(var(--color-primary)) !important;
}

/* dropdown, button hover/focus */
.ant-dropdown-menu-item-group-list > div > div .ant-btn:focus,
.ant-dropdown-menu-item-group-list > div > div .ant-btn:hover,
.ant-row.buttons-sticky > div .ant-btn:focus,
.ant-row.buttons-sticky > div .ant-btn:hover,
.ant-space-item .ant-btn:focus,
.ant-space-item .ant-btn:hover,
.ant-upload .ant-btn:focus,
.ant-upload .ant-btn:hover {
    border-color: unset;
}

/* input, select, picker hover/focus */
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-focused,
.ant-picker:hover:not(.ant-picker-disabled),
.ant-input:hover,
.ant-input-focused,
.ant-input:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
    border-color: rgb(var(--color-primary));
    box-shadow: rgb(var(--color-primary));
}

/* radio input focus */
.ant-radio-input:focus + .ant-radio-inner,
.ant-input-number-focused,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: rgba(var(--color-primary), 0.2);
}

/* button, table column sorter */
.ant-btn:focus,
.ant-btn:hover,
.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
    color: rgb(var(--color-primary));
}

/* search field, checkbox, tree hover/focus */
.ant-btn.ant-btn-default:hover:not(:disabled),
.ant-input-search .ant-input:focus,
.ant-input-search .ant-input:hover:not(:disabled),
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner:not(:disabled),
.ant-tree-checkbox:hover .ant-tree-checkbox-inner:not(:disabled),
.ant-tree-checkbox-checked::after,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner:not(:disabled),
.ant-checkbox:hover .ant-checkbox-inner:not(:disabled) {
    border-color: rgb(var(--color-primary));
}

/* upload, space item button focus */
.ant-upload,
.ant-space-item {
    .ant-btn:focus,
    .ant-btn:hover {
        border-color: rgb(var(--color-primary));
    }
}

/* pagination active item */
.ant-table.ant-table-small .ant-table-filter-trigger,
.ant-pagination-item-active a,
.ant-pagination-item:hover a {
    color: rgb(var(--color-primary)) !important;
}

/* button ghost primary */
.ant-btn-background-ghost.ant-btn-primary,
.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
    color: rgb(var(--color-primary));
    border-color: rgb(var(--color-primary));
}

/* radio button outline */
.ant-radio-group.ant-radio-group-outline
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-width: 1px;
    color: rgb(var(--color-primary));
    border-color: rgb(var(--color-primary));
}

/* button focus, hover on ghost background */
.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover,
a {
    color: rgb(var(--color-primary)) !important;
}

/* input, select, other elements border on focus */
.ant-pagination-item-active,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-checkbox-checked::after,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-input-number-focused,
.ant-input-number:hover:not(.ant-input-number-disabled) {
    border-color: rgb(var(--color-primary));
}

/* select item selected */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(var(--color-primary), 0.15);
}

/* table cell background */
.ant-table-cell {
    .ant-btn.ant-btn-default.ant-btn-icon-only.ny-search-field-button:not(.ant-btn[disabled]),
    .ant-btn:not(.ant-btn-primary),
    .ant-input,
    .ant-picker,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
    }
}

/* menu horizontal items */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
    border-bottom: rgb(var(--color-primary, 24, 144, 255));
}

/* radio button first-child border */
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: rgb(var(--color-primary));
}

/* submenu arrow color */
.ant-menu-submenu-selected .ant-menu-submenu-arrow::after,
.ant-menu-submenu-selected .ant-menu-submenu-arrow::before,
.ant-menu-item-checked.ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    color: rgb(var(--color-primary));
}

/* ant slider */

.ant-slider-handle {
    background: rgb(var(--color-primary)) !important;
}

.ant-slider-handle:focus,
.ant-slider-handle,
.ant-slider-dot-active {
    border-color: rgb(var(--color-primary));
}

.ant-slider-track {
    background: rgba(var(--color-primary), 0.35);
}

.ant-slider:hover .ant-slider-handle,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: rgba(var(--color-primary), 0.6);
}

.ant-slider:hover .ant-slider-track {
    background: rgba(var(--color-primary), 0.6);
}

/* ant steps */

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: rgb(var(--color-primary));
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: rgb(var(--color-primary));
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: rgb(var(--color-primary));
}

/* map select */

.ant-select.ant-select-borderless.ant-select-in-form-item.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    border-color: rgb(var(--color-primary));
}

/* map select icon */

.ant-timeline-item-head-blue {
    color: rgb(var(--color-primary));
    border-color: rgb(var(--color-primary));
}

.ant-select.ant-select-borderless.ant-select-in-form-item.ant-select-single.ant-select-show-arrow.ant-select-show-search,
.ant-timeline-item-head-blue {
    border-color: rgb(var(--color-primary)) !important;
}

/* travel warrant type list */

.ant-list-item > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: transparent;
}

/* travel warrant legend button */

.travel-warrant-overview-legend {
    color: rgb(var(--color-primary));
    border-color: rgb(var(--color-primary)) !important;
}

/* meals webshop*/

.fc .fc-highlight {
    background: rgba(var(--color-primary), 0.1) !important;
}

/* note icon */

.ant-badge:has(.anticon-exclamation-circle) > {
    .anticon.anticon-form.ny-note-modal-icon {
        color: rgb(var(--color-primary)) !important;
    }
}

/* sidemenu favorites */

.ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65) !important;
}

/* less */

.statistic-card-items-path .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: rgb(var(--color-primary, 24, 144, 255)) !important;
}

.statistic-card-items-path .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: rgb(var(--color-primary, 24, 144, 255)) !important;
}

.statistic-card-items-path .ant-steps-icon-dot {
    background: rgb(var(--color-primary, 24, 144, 255)) !important;
    color: rgb(var(--color-primary, 24, 144, 255)) !important;
}

.statistic-card-items-path .ant-steps-item-tail {
    background-color: rgb(var(--color-primary, 24, 144, 255)) !important;
}

.statistic-card-items-path .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: rgb(var(--color-primary, 24, 144, 255));
}

.ant-card-head {
    border-bottom: 2px solid rgb(var(--color-primary));
}

.ant-modal-header {
    border-bottom: 2px solid rgb(var(--color-primary));
}

.ant-spin-dot-item {
    background-color: rgb(var(--color-primary));
}

.bell-icon:hover > span > span {
    color: rgb(var(--color-primary));
}
.bell-icon:focus > span > span {
    color: rgb(var(--color-primary));
}

.avatar-icon-custom:hover > div > div > span > span {
    color: rgb(var(--color-primary));
}

.accessibility-icon:hover {
    color: #1064b2 !important;
}

.dropdown-arrow-icon {
    top: -4px;
    position: relative;
    transform: rotate(90deg);
    transition: transform 0.2s ease, top 0.2s ease, left 0.2s ease;
}

.dropdown-arrow-icon-active {
    top: -4px;
    position: relative;
    left: 4px;
    transform: rotate(-90deg);
    transition: transform 0.2s ease, top 0.2s ease, left 0.2s ease;
}

.ant-space-item .ant-btn:focus > div > div {
    .ant-avatar {
        color: rgb(var(--color-primary)) !important;
    }
}

.ant-alert-message {
    font-size: 14px;
}
