.ny-icon {
    width: 1em;
}

.ny-icon-padding {
    width: 1em;
    padding-top: 4px;
}

.ny-icon-rec {
    width: 1em;
    font-size: 2em;
    padding-top: 5px;
}

.ny-icon-rec-div :hover {
    color: rgb(64, 169, 255);
}
