.marquee {
    white-space: nowrap;
    overflow: hidden;
    span {
        display: inline-block;
        width: 100%;
        span {
            position: relative;
            overflow: hidden;
            left: 0px;
            transition: transform 3s;
        }
    }
    &:active,
    &:hover {
        span {
            width: auto;
            span {
                transform: translateX(-62%);
            }
        }
    }
}

.menu-element {
    // position: absolute;
    // top: 0px;
    // margin-left: 22px;
	// display: inline-block;
    height: 32px;
    // width: 130px;
}

.ant-layout-sider-trigger,
.ant-layout-sider-children {
    // border-right: 1px solid rgba(160, 160, 160, 0.3);
}
