.menu-search {
    width: min(350px, calc(30vw - var(--sticky-width)));
    min-width: 100px;
    transition: width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s;
    /* margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px; */
}

.metadata-search {
    width: 190px;
    margin-left: 108px;
    margin-top: 49px;
    position: absolute;
    z-index: 999;
}

.header-title {
    margin-left: 25px;
    font-weight: bold;
    font-size: 18px;
}

/* .main-layout {
    margin-left: 200px !important;
    padding: 3px !important;
}

.main-layout-collapsed {
    margin-left: 80px !important;
    padding: 3px !important;
} */

/* @media only screen and (max-width: 992px) {
    .main-layout {
        margin-left: 0px !important;
    }
} */

/* .data-table-header-height .ant-table-tbody {
    max-height: 73.5vh;
} */

.main-layout .main-content .edit-tab .ant-tabs-bar {
    margin-bottom: 0px;
}

.main-layout .main-content .edit-tab .ant-tabs-content {
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    padding: 1em;
    margin-bottom: 1em;
}

.download-card {
    background: #ffffff00 !important;
}

.ant-page-header-heading-extra {
    padding-top: 4px !important;
}

/* .ny-header {
    background-color: #fff;
    border-bottom: 1px solid rgba(160, 160, 160, 0.3);
    z-index: 2;
    padding-left: 10px;
    width: 100%;
    position: fixed !important;
} */

.ny-sider {
    position: fixed;
    left: 0;
    z-index: 1;
    padding-bottom: 50px;
    top: 53px;
    /* border-right: 1px solid rgba(160, 160, 160, 0.3); */
}

.ny-pinned {
    height: 85vh;
    right: 0;
    z-index: 1;
}

.ny-menu {
    height: 100%;
    border-right: 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

.header-menu {
    height: 64px;
    border-bottom: 1px solid rgba(160, 160, 160, 0.3) !important;
}

.header-menu > .ant-menu-item {
    height: 64px;
    border-bottom: 2px solid transparent !important;
    padding: 0 10px;
}

.header-menu > .ant-menu-item:hover {
    border-top: 2px solid #1890ff !important;
}

.ant-table-row:hover {
    /* background: #dddddd !important; */
    cursor: pointer;
}

.button-success {
    background-color: #228b22 !important;
    color: white !important;
}

.button-success:hover {
    background-color: #006400 !important;
}

.button-warning {
    background-color: #ee7b00 !important;
    color: white !important;
}

.button-warning:hover {
    background-color: #cc5900 !important;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0);
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.45);
}

.buttons-sticky .ant-col {
    text-align: right;
}

.buttons-sticky button {
    margin-right: 1em;
}
.ny-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    padding: 5px;
}
.ny-widget-card {
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.4); */
    height: 100%;
    border-radius: 15px !important;
}

.ny-graph-card {
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.4); */
    height: 100%;
    border-radius: 15px !important;
}

.ny-travel-warrant-card {
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.4); */
    border-radius: 15px !important;
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important;
}

.ny-travel-warrant-card .ant-card-actions {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.ny-travel-warrant-card .ant-card-actions > li > span > .anticon {
    font-size: 18px;
    line-height: 26px;
}

.ny-travel-warrant-card .ant-card-head-title {
    font-size: 15px !important;
}

.travel-warrant-overview-footer {
    justify-content: end;
    display: flex;
    min-height: 3rem;
}

.extraFile:hover {
    color: #1890ff;
}

.menu-added-box-shadow {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.overview-icons {
    padding: 6px;
    border-radius: 50%;
    color: white;
}

.travel-warrant-steps {
    display: flex;
    width: auto;
}

.steps-container {
    width: 100%;
    display: inline-flex;
    position: relative;
    /* margin-top: 3.6rem; */
    margin-left: -1.6rem;
}

.travel-warrant-cards-title {
    font-size: 16px;
    margin-left: 35px;
    margin-bottom: 10px;
}

.travel-warrant-cards-widget-container {
    margin-top: 1rem;
    margin-right: 1rem;
    display: flex;
}

.travel-warrant-cards-widgets {
    width: 280px;
    height: 109px;
    margin-left: 1.6rem;
    /*  // margin-right: 1.6rem; */
}

.employee-leave-cards-widgets {
    width: 280px;
    height: 42px;
    margin-left: 1.6rem;
}

.travel-warrant-cards-absence {
    width: 370px;
    height: 109px;
    margin-left: 1.6rem;
    /*  // margin-right: 1.6rem; */
}

.travel-warrant-cards-widgets-mid {
    /*     width: 390px;
    height: 420px; */
    margin-left: 1.7%;
    margin-right: 1.6%;
}

.travel-warrant-cards-widgets-mid-admin {
    /*  width: 390px;
    height: 420px; */
    margin-left: 1.6rem;
}

.travel-warrant-card-print-margin {
    margin-left: 8px;
    margin-right: 8px;
}

.item-note {
    position: relative;
    align-items: center;
    display: flex;
    font-size: 18px;
    margin-left: 8px;
}

.overview-buttons-sticky .ant-popover-content {
    margin: 3rem;
}

.travel-warrant-popover {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.steps-container .ant-steps-item-tail {
    /*   justify-content: center;
    display: flex;
    width: 60%; */
    /* // margin-right: 12%; */
    width: 0px;
}

.travel-warrant-steps-icon {
    /*  font-size: 36px !important; */
    top: -7px;
    position: relative;
}

.unapproved-table .ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

/* .ant-steps-item-container {
    height: 2.6rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
} */

.travel-warrant-cards {
}

.overview-cards-paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 200px; */
}

.ny-table-paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}

.ny-table-paragraph-wrap {
    /*  max-width: 287px; */
    max-height: 50px;
}

.ny-table-paragraph-div {
    /*   white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    max-width: 200px;
    max-height: 50px;
}

.ny-table-paragraph-div-wide {
    max-width: 300px;
    max-height: 50px;
}

.overview-cards-step .ant-steps-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70% !important;
}

.overview-cards-step-paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all !important;
    margin: 0rem !important;
}

.overview-cards-paragraph-div {
    /*   white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    max-width: 200px;
    max-height: 50px;
}

.overview-cards-description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all !important;
}

.ny-metadata-paragraph-div {
    max-width: 287px;
    max-height: 50px;
    margin-right: -110px;
}

.ny-metadata-paragraph-right-div {
    max-width: 235px;
    max-height: 50px;
    margin-right: -93px;
}

.ny-metadata-wide-left-paragraph-div {
    max-width: 720px;
    max-height: 50px;
    margin-right: -320px;
}

.ny-metadata-wide-right-paragraph-div {
    max-width: 735px;
    max-height: 50px;
    margin-right: -331px;
}

.overview-cards-description-div-inner {
    overflow: hidden;
    text-overflow: ellipsis;

    max-height: 42px;
    margin-right: 28px;
}

.ny-widget-card .ant-card-head {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.ny-travel-warrant-card .ant-card-head {
    /* //  border-bottom: 0px solid #006400 !important; */
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.ny-travel-warrant-card .ant-card-body {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    height: 280px !important;
}

.travel-warrant-cards-widgets .ant-card-body {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.ny-card-static {
    cursor: default !important;
}

.ny-card-link:hover {
    color: rgb(var(--color-primary, #1890ff)) !important;
}

.green-button {
    border: 0px !important;
    background-color: #20b81b !important;
}

.green-button:focus {
    background-color: #20b81b !important;
}

.yellow-button {
    border: 0px !important;
    background-color: #faad14 !important;
}

.yellow-button:focus {
    background-color: #faad14 !important;
}

.purple-button {
    border: 0px !important;
    background-color: #0d4579 !important;
}

.purple-button:focus {
    background-color: #0d4579 !important;
}

.ny-directory-widget-list .ant-list-empty-text {
    display: none !important;
}
.ny-directory-widget-list .ant-col {
    height: 100%;
}
.ny-widget-list .ant-col {
    height: 100%;
}
.ny-widget-card .ant-card-extra {
    padding: 12px 0 !important;
}

.ny-travel-warrant-card .ant-card-extra {
    padding: 12px 0 !important;
    top: 2px;
    position: relative;
}

.ny-travel-warrant-card .ant-tooltip-open {
    background: var(--color-soft-primary);
}

.ny-card-icon {
    font-size: 16px;
    vertical-align: middle !important;
}

.ny-card-icon:hover {
    color: rgb(var(--color-primary, #1890ff));
}

.ny-card-link .ant-image {
    vertical-align: middle;
}

.ny-card-link-img {
    width: 16px !important;
    float: left;
    margin: 0 10px;
}

.ny-favorit-icon {
    float: right;
    padding-top: 3px;
    font-size: 16px;
    cursor: pointer;
}

.ny-favorit-icon:hover {
    color: #ffd903;
}

.ny-favorit-icon-active {
    float: right;
    padding-top: 3px;
    font-size: 16px;
    cursor: pointer;
    color: #ffd903 !important;
}
.ny-favorit-icon-active:hover {
    color: #ffed87 !important;
}
.ny-fixed-widgets-button {
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important;
    font-size: 22px !important;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 0.15), 0 6px 16px rgb(0 0 0 / 0.15), 0 9px 28px 8px rgb(0 0 0 / 0.15) !important;
    transition: color 0.3s !important;
}
.dashboard-widget-box-unlock:hover {
    cursor: grab;
}
.dashboard-widget-box-unlock:active {
    cursor: grabbing;
}
.description-div {
    max-width: 300px;
    height: 2.5vh;
}
.description-div-small {
    max-width: 200px;
    height: 2.5vh;
}
.description-div-large {
    max-width: 400px;
    height: 2.5vh;
}
.description-paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
