.menu-pin-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    &:active,
    &:hover {
        opacity: 1;
    }
}
